<template>
  <layout-base>
    <template v-slot:header><slot name="header"></slot></template>
    <template v-slot:default>
      <div class="menu-tall bg-panel">
        <div class="menu-frame flex flex-col justify-center">
          <slot name="menu"></slot>
        </div>
      </div>
    </template>
    <template v-slot:footer><slot name="footer"></slot></template>
  </layout-base>
</template>

<script>
import LayoutBase from "@/components/layouts/LayoutBase";
export default {
  name: "LayoutMenuTall",
  components: { LayoutBase }
};
</script>

<style scoped>
.menu-tall {
  width: 40em;
  margin: 5vh auto;
}
.menu-frame {
  text-align: center;
  padding: 4em;
}
</style>
