<template>
  <button-action
    :icon="icon"
    :label="label"
    class="button-big"
    @click="$emit('click')"
    ><template v-slot:icon v-if="this.$slots['icon']"
      ><slot name="icon"></slot></template
  ></button-action>
</template>

<script>
import ButtonAction from "@/components/buttons/ButtonAction";

export default {
  name: "ButtonActionBig",
  components: { ButtonAction },
  props: {
    label: {
      type: String,
      default: "Button"
    },
    icon: {
      type: String,
      default: null
    }
  }
};
</script>

<style scoped>
.button-big {
  background-color: var(--color-button-normal);
  border: none;
  border-radius: 0.5em;
  color: #aaa;
  padding: 0.5em;
  font-size: 1.5em;
  width: 20rem;
}
.button-big:hover {
  cursor: pointer;
  background-color: var(--color-button-hover);
}
.button-big:active {
  cursor: pointer;
  background-color: var(--color-button-active);
}
</style>
