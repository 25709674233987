<template>
  <div class="button-action" @click="$emit('click')">
    <div class="icon" v-if="this.$slots['icon']"><slot name="icon"></slot></div>
    <img class="icon" v-bind:src="icon" v-if="icon" />
    <span class="label" v-if="label" v-text="label"></span>
  </div>
</template>

<script>
export default {
  name: "ButtonAction",
  props: {
    label: {
      type: String,
      default: "Button"
    },
    icon: {
      type: String,
      default: null
    }
  }
};
</script>

<style scoped>
.button-action > .label {
  vertical-align: baseline;
  user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
}
.button-action > .icon {
  display: inline-block;
  position: relative;
  top: -0.1em;
  width: 1.2em;
  height: 1.2em;
  vertical-align: middle;
}
.button-action > .icon > svg,
.button-action > .icon > img {
  width: 100%;
  height: 100%;
}
.button-action > .icon:not(:last-child) {
  margin-right: 0.5em;
}
</style>
