<template>
  <div id="disclaimer" v-text="$t('views.disclaimer.text')"></div>
</template>

<script>
export default {
  name: "Disclaimer"
};
</script>

<style scoped>
#disclaimer {
  color: var(--color-text-reg);
  background-color: #212225;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1em 2em 2em 2em;
  box-sizing: border-box;
  text-align: center;
}
</style>
