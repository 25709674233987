<template>
  <layout-menu-tall>
    <template v-slot:header>
      <img
        src="@/assets/logos/logo-stats.svg"
        alt="Logo"
        class="mx-auto mb-8 mt-16"
        style="height: 15vh;opacity: 0.3;"
      />
    </template>
    <template v-slot:menu>
      <router-link
        :to="{ name: 'combat-ships' }"
        class="flex-1 inline-block mx-auto mb-2"
        ><button-big :label="$t('views.home.ship_stats')"></button-big
      ></router-link>
      <p class="mt-4" v-html="$t('views.home.text')"></p>
    </template>
    <template v-slot:footer>
      <disclaimer />
    </template>
  </layout-menu-tall>
</template>

<script>
import Disclaimer from "@/components/misc/Disclaimer";
import ButtonBig from "@/components/buttons/ButtonActionBig";
import LayoutMenuTall from "@/components/layouts/LayoutMenuTall";
export default {
  name: "Home",
  components: { LayoutMenuTall, ButtonBig, Disclaimer }
};
</script>
