<template>
  <div class="app-container">
    <header>
      <slot name="header"></slot>
    </header>
    <main><slot name="default"></slot></main>
    <footer><slot name="footer"></slot></footer>
  </div>
</template>

<script>
export default {
  name: "LayoutBase"
};
</script>

<style scoped>
.app-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: calc(100vh + 72px);
}
header {
  width: 100%;
  flex-shrink: 0;
  box-sizing: border-box;
  text-align: center;
}
main {
  width: 100%;
  flex-grow: 1;
}
footer {
  width: 100%;
  flex-shrink: 0;
  box-sizing: border-box;
  text-align: center;
}
</style>
